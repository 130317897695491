<template>
    <div></div>
</template>

<script>
    import Vue from 'vue';
    import isEmpty from 'lodash/isEmpty';
    import { mapActions } from 'vuex';
    import apiConstants from '@core/utils/constants/api';
    import WarnBeforeLeave from '@core/mixins/WarnBeforeLeave';
    import zIndex from '@core/scss/mixins/_z-index-export-js.scss';

    export default {
        name: 'EventReservation',
        mixins: [
            WarnBeforeLeave
        ],
        props: {
            payload: {
                type: Object,
                default: ( ) => { return { }; }
            },
            callbackAddItemToCartErrorFn: {
                type: Function,
                default: ( ) => ( ) => { }
            },
            busEventParent: {
                type: Object,
                default: null
            }
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.eventV2.theEvent._components.progressCircularAnimationModal'
        },
        data() {
            return {
                busEventProgressCircular: new Vue( ),
                previousRoute: null
            };
        },
        computed: {
            routeRedirectOnError() {
                return this.$route.meta.routeRedirectOnError;
            }
        },
        methods: {
            ...mapActions( {
                addItemToCart                   : 'cart/addItem',
                instantDeleteCurrentCart        : 'cart/instantDeleteCurrentCart'
            } ),
            showProgressAnimationModal( payload ) {
                let response;
                let hasHotel = !!payload.hotelReservation;

                this.$modal.show(
                    import ( '@tenant/app/components/modals/ProgressCircularAnimationModal.vue' ),
                    {
                        title: this.$t( hasHotel ? 'package.title' : 'ticketsOnly.title' ),
                        busEvent: this.busEventProgressCircular,
                        variant: 1
                    },
                    {
                        style: `z-index: ${zIndex.highest}`,
                        'no-click-animation': true,
                        persistent: true,
                        scrollable: false,
                        fullscreen: false,
                        'hide-overlay': false,
                        'max-width': 600
                    },
                    {
                        open: async ( ) => {
                            this.warnBeforeLeaveMessage = this.$t( '_common:messages.leaveRouteDuringReservationConfirm' );
                            this.forceWarnBeforeLeaveMixin( );

                            response = await this.addItemToCart( payload );
                            this.unbindWarnBeforeYouLeaveMixin( );
                            if ( response.success ) {
                                if( response.code === apiConstants.WARNING_CODES.EVENT_PROVIDER.FAN_SESSION_RESALE_EXPIRED ) {
                                    this.openFanSessionResaleExpiredModal( response );
                                } else {
                                    if( this._destroyed ) {
                                    this.instantDeleteCurrentCart( );
                                }
                                this.busEventProgressCircular.$emit( 'end' );
                                }
                            } else {
                                this.busEventProgressCircular.$emit( 'close' );
                            }
                        },
                        beforeClose: ( ) => {
                            if ( response?.success  ) { //response is optional. User can close the reservation route
                                if ( this._destroyed ) {
                                    this.instantDeleteCurrentCart( );
                                } else {
                                    this.$router.replace( { name: 'cartCheckout', params: { cartId: response.cartId } } );
                                }
                            } else {
                                this.$router.replace( this.previousRoute ? this.previousRoute.fullPath : { name: this.routeRedirectOnError, params: { id: this.$route.params.id } } );
                                response && this.callbackAddItemToCartErrorFn( response );
                            }
                        }
                    }
                );
            },
            openFanSessionResaleExpiredModal ( response ) {
                const currency = this.payload.hotelReservation.room.selectedRate.currency;
                const newTotalPrice = this.$options.filters.currencyFilter( response.totalPrice, currency );
                const i18nPrefix = 'main:views.eventV2.theEvent._components.eventFanSessionResaleExpiredModal';
                const title = this.$t( `${i18nPrefix}.title` );
                const content = this.$t( `${i18nPrefix}.content`, { newTotalPrice: newTotalPrice } );
                const cancelButton = this.$t( `${i18nPrefix}.buttons.cancel` );
                const confirmButton = this.$t( `${i18nPrefix}.buttons.confirm` );

                this.$modal.show(
                    (  ) => import( '@tenants/ticketmaster/app/components/modals/ConfirmationModal' ),
                    {
                        title,
                        content,
                        cancelButton,
                        confirmButton,
                        onCancelFn: ( ) => {
                            this.busEventParent.$emit( 'event-fan-session-expired-back' );
                            this.instantDeleteCurrentCart( );
                            this.$router.replace( this.previousRoute ? this.previousRoute.path : { name: this.routeRedirectOnError, params: { id: this.$route.params.id } } );
                            this.busEventProgressCircular.$emit( 'close' );
                        },
                        onConfirmFn: ( ) => this.busEventProgressCircular.$emit( 'end' )
                    },
                    {
                        'max-width': 858,
                        persistent: true,
                    }
                );

            },
        },
        created( ) {
            if ( !isEmpty( this.payload ) ) {
                this.showProgressAnimationModal( this.payload );
            } else {
                this.$router.replace( { name: this.routeRedirectOnError, params: { id: this.$route.params.id } } );
            }
        },
        beforeRouteEnter( to, from, next ) {
            next( ( cmp ) => {
                cmp.previousRoute = from;
            } );
        },
        destroyed( ) {
            this._destroyed = true;
        }
    };
</script>
